import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

const CustomerSays = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useEffect(() => {
    if (prevRef.current && nextRef.current) {
      // Now assign the navigation elements after component is rendered
      // Ensure navigation buttons are initialized with the swiper instance
    }
  }, [prevRef, nextRef]);

  return (
    <>
      <div className="container text-center py-3 mt-3 main_Customersays">
        <h2 className="display-5 mb-4 fw-bold Customer_Says main_change">
          What our{" "}
          <strong
            className="fw-bold"
            style={{
              color: "#25AD0F",
            }}
          >
            Customers
          </strong>
          &nbsp;say about us
        </h2>
        {/* <div className="d-flex justify-content-center">
          <p
            className="text-muted mb-4 col-lg-8 col-md-12 text-center"
          >
            We help you connect with your audience, build your brand, and
            achieve your business goals through innovative digital marketing
            solutions.
          </p>
        </div> */}

      </div>

      {/* Swiper Slider for Cards */}
      <div className="container">
        <Swiper
          spaceBetween={30}
          loop={true}
          grabCursor={true}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          modules={[Navigation, Pagination]}
        >
          <SwiperSlide>
            <div className="card" style={{ height: "320px" }}>
              <div className="d-flex justify-content-between p-3" >
                <img
                  src="/assets/images/s.png"
                  className="card-img-top"
                  style={{ height: "100px", width: "100px" }}
                  alt="Laptop"
                />
                <div className="ms-auto text-warning d-flex">
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                </div>
              </div>

              <div className="card-body" >
                <div className="d-flex justify-content-between"></div>
                <div className="d-flex justify-content-between mb-1">
                  <h5 className="mb-0">Aryan Sharma</h5>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p className=" mb-0">
                    EL TUTOR has been a lifesaver for my studies. I found a tutor who really understands my learning style and has helped me improve my grades significantly.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card" style={{ height: "320px" }}>
              <div className="d-flex justify-content-between p-3">
                <img
                  src="/assets/images/sc.png"
                  className="card-img-top"
                  style={{ height: "100px", width: "100px" }}
                  alt="Laptop"
                />
                <div className="ms-auto text-warning d-flex">
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStarHalfAlt style={{ marginRight: "5px" }} />
                </div>
              </div>

              <div className="card-body" >
                <div className="d-flex justify-content-between"></div>
                <div className="d-flex justify-content-between mb-1">
                  <h5 className="mb-0">Priya Patel</h5>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p className=" mb-0">
                    I was struggling with math, but my tutor on EL TUTOR has made it so much easier to understand. I'm finally confident in my abilities.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card" style={{ height: "320px" }}>
              <div className="d-flex justify-content-between p-3">
                <img
                  src="/assets/images/per.png"
                  className="card-img-top"
                  style={{ height: "100px", width: "100px" }}
                  alt="Laptop"
                />
                <div className="ms-auto text-warning d-flex">
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStarHalfAlt style={{ marginRight: "5px" }} />
                </div>
              </div>

              <div className="card-body" >
                <div className="d-flex justify-content-between"></div>
                <div className="d-flex justify-content-between mb-1">
                  <h5 className="mb-0">Anjali Singh</h5>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p className=" mb-0">
                    I love the flexibility of EL TUTOR. I can schedule sessions that fit my busy schedule, and I've found some amazing tutors who are patient and supportive.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="card" style={{ height: "320px" }}>
              <div className="d-flex justify-content-between p-3">
                <img
                  src="/assets/images/t.png"
                  className="card-img-top"
                  style={{ height: "100px", width: "100px" }}
                  alt="Laptop"
                />
                <div className="ms-auto text-warning d-flex">
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStarHalfAlt style={{ marginRight: "5px" }} />
                </div>
              </div>

              <div className="card-body" >
                <div className="d-flex justify-content-between"></div>
                <div className="d-flex justify-content-between mb-1">
                  <h5 className="mb-0">Sneha Patel, (English Tutor)</h5>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p className=" mb-0">
                    EL Tutor provides the perfect platform to do just that. I love being able to teach students from different backgrounds and watch them grow.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="card" style={{ height: "320px" }}>
              <div className="d-flex justify-content-between p-3">
                <img
                  src="/assets/images/p.png"
                  className="card-img-top"
                  style={{ height: "100px", width: "100px" }}
                  alt="Laptop"
                />
                <div className="ms-auto text-warning d-flex">
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStar style={{ marginRight: "5px" }} />
                  <FaStarHalfAlt style={{ marginRight: "5px" }} />
                </div>
              </div>

              <div className="card-body" >
                <div className="d-flex justify-content-between"></div>
                <div className="d-flex justify-content-between mb-1">
                  <h5 className="mb-0">Mr. Gupta</h5>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <p className=" mb-0">
                    We're so grateful for EL TUTOR. Our daughter has made tremendous progress with her studies since she started using the platform.                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

    </>
  );
};

export default CustomerSays;
